<template>
  <main class="bg-white">
    <section class="core">
      <div class="title-box row-between">
        <div class="title bg-primary white lg row-center">物流信息</div>
        <div class="tel warn nr">
          有疑问可联系我们：{{ config.purchase_phone }}
        </div>
      </div>
      <div class="logistics-box">
        <div class="info-text lighter nr mb20">
          <p v-if="deliverOrder.send_type == 1">
            <em style="padding-right:20px;">快递物流</em>
            <em>运单号码：</em><span>{{ deliverOrder.invoice_no }}</span>
            <em v-if="deliverOrder.shipping_name">物流公司：</em><span>{{ deliverOrder.shipping_name }}</span>
            <!-- <em>客服电话：</em><span>010-888-8888</span> -->
          </p>
          <p v-if="deliverOrder.send_type == 2">
            <em style="padding-right:20px;">厂家自配</em>
            <em>送货人：</em><span>{{ deliverOrder.shr_name }}</span>
            <em>联系电话：</em><span>{{ deliverOrder.shr_tel }}</span>
            <!-- <em>客服电话：</em><span>010-888-8888</span> -->
          </p>
          <!-- <p>
            <em>发货地址：</em>浙江省金华市义乌市
            拥军路和宗泽北路红绿灯路口宝娜斯仓库2楼　010-888-88888
          </p> -->
          <p>
            <em>收货地址：</em>{{ deliverTake.address }} {{
              deliverTake.contacts
            }}收 {{ deliverTake.mobile }}
          </p>
          <!-- <p v-if="this.$store.getters.userInfo.company_id !== 57">
            <em>收货地址：</em>{{ deliverTake.address }} {{
              deliverTake.contacts
            }}收 {{ deliverTake.mobile }}
          </p>
          <p v-else>
            收货地址：  湖南省株洲市石峰区田心高科园博雅路8号  兰花  18973388387
              <hr>
            送货人： 宾伟翔   13762365954
          </p> -->
        </div>
        <div class="info-img mb20" v-show="true">
          <el-image :src="item" class="item-img" v-for="(item, index) in deliverOrder.image" :key="index" fit="cover"
            :preview-src-list="deliverOrder.image"></el-image>
        </div>
        <div class="info-logistics"> <!--aaaa-->
          <div class="logistics-list pr">
            <!-- 下单 -->
            <div class="logistics-item pr row sm" v-if="this.deliverBuy.tips">
              <div class="item-time lighter">
                <span>{{ deliverBuy.time }}</span>
              </div>
              <div class="item-detail">
                <div class="detail-item">
                  {{ deliverBuy.title }}
                </div>
                <div class="detail-item">
                  {{ deliverBuy.tips }}
                </div>
              </div>
            </div>

            <!-- 交易状态 -->
            <div class="logistics-item pr row sm" v-if="deliverFinish.tips">
              <div class="item-time lighter">
                <span>{{ deliverFinish.time }}</span>
              </div>
              <div class="item-detail">
                <div class="detail-item">
                  {{ deliverFinish.title }}
                </div>
                <div class="detail-item">
                  {{ deliverFinish.tips }}
                </div>
              </div>
            </div>
            <!-- 跟踪物流 -->
            <div class="logistics-item pr row sm" v-if="delivery.traces && delivery.traces.length">
              <div class="item-time lighter">
                <span>{{ delivery.time }}</span>
              </div>
              <div class="item-detail">
                <div class="detail-item">
                  {{ delivery.title }}
                </div>
              </div>
            </div>

            <div class="logistics-item pr row sm" v-for="(item, index) in this.delivery.traces" :key="index">
              <div class="item-time lighter">
                <span>{{ item[0] }}</span>
              </div>
              <div class="item-detail">
                <div class="detail-item">
                  {{ item[1] }}
                </div>
              </div>
            </div>
<!--            &lt;!&ndash; 收货 &ndash;&gt;-->
<!--            &lt;!&ndash; <div class="logistics-item pr row sm" v-if="deliverFinish.tips">-->
<!--              <div class="item-time lighter">-->
<!--                <span>派送中</span>-->
<!--              </div>-->
<!--              <div class="item-detail">-->
<!--                <div class="detail-item">-->
<!--                  {{deliverTake.mobile}}-->
<!--                </div>-->
<!--                <div class="detail-item">-->
<!--                  {{deliverTake.address}}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div> &ndash;&gt;-->
            <!-- 完成 -->
            <div class="logistics-item pr row sm" v-if="deliverShipment.tips">
              <div class="item-time lighter">
                <span>{{ deliverShipment.time }}</span>
              </div>
              <div class="item-detail">
<!--                <div class="detail-item">-->
<!--                  {{ deliverShipment.title }}-->
<!--                </div>-->
                <div class="detail-item">
                  {{ deliverShipment.tips }}
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        <div v-else>-->
<!--          <div class="block">-->
<!--            <div class="logistics-box">-->
<!--              <div class="info-logistics">-->
<!--                &lt;!&ndash; 排序：-->
<!--            <el-radio-group v-model="reverse">-->
<!--              <el-radio :label="true">倒序</el-radio>-->
<!--              <el-radio :label="false">正序</el-radio>-->
<!--            </el-radio-group>  &ndash;&gt;-->
<!--                <el-timeline :reverse="reverse">-->
<!--                  <el-timeline-item v-for="(activity, index) in activities" :key="index"-->
<!--                    :timestamp="activity.approval_date">-->

<!--                    {{ activity.timestamp }}-->

<!--                    {{ activity.content }}-->



<!--                  </el-timeline-item>-->
<!--                </el-timeline>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </section>
  </main>
</template>
<script>
import { status } from "nprogress";
import { mapState } from "vuex";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      orderId: this.orderId, //订单Id
      deliverBuy: {},
      delivery: {},
      deliverFinish: {},
      deliverOrder: {},
      deliverShipment: {},
      deliverTake: {},
      activities: [
        // {
        //   content: '下单',
        //   timestamp: '2023-11-21 15:36:15'
        // }, {
        //   content: '创建成功',
        //   timestamp: '2023-11-21 15:36:15'
        // }, {
        //   content: '通过审核',
        //   timestamp: '2023-11-21 17:21:51'
        // }, {
        //   content: '备货中',
        //   timestamp: '2023-11-21 17:56:09'
        // }, {
        //   content: '送货中',
        //   timestamp: '2023-11-22 09:06:25'
        // }
        // , {
        //   content: '已经签收',
        //   timestamp: '2023-11-22 10:36:52'
        // }
      ]
    };
  },
  computed: {
    ...mapState(["config"]),
  },
  watch: {},
  methods: {
    /**
     * @desctiption 获取物流详情
     * @return void
     */
    async getOrderTraces() {
      let { code, data } = await this.$api.getOrderTracesApi({
        id: this.orderId,
      });
      if (code == 1) {
        let { buy, delivery, finish, order, shipment, take } = data;
        this.deliverBuy = buy;
        this.delivery = delivery;
        this.deliverFinish = finish;
        order.image = order.image.split(",");
        this.deliverOrder = order;
        this.deliverShipment = shipment;
        this.deliverTake = take;
        this.activities = [
          {
            content: buy.title,
            timestamp: buy.time
          }, {
            content: shipment.title,
            timestamp: shipment.time
          }, {
            content: finish.tips,
            timestamp: finish.time
          }].filter(activity => activity.content && activity.timestamp);;
          // 如果 finish.time 不为空，隐藏 activities 中的第二个对象
          if (finish.time) {
              this.activities[1].hidden = true;
          }
      }
    },
  },
  created() {
    this.orderId = this.$route.query.id;
    this.getOrderTraces();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
main {
  overflow: hidden;
}

.title-box {
  padding-top: 37px;

  .title {
    width: 127px;
    height: 46px;
    background: #44aaea;
  }
}

.logistics-box {
  border: $-solid-border;
  padding: 35px 25px 30px;
  margin-bottom: 75px;

  .info-text {
    p {
      line-height: 2;

      em {
        color: $-color-normal;
      }

      span {
        margin-right: 10px;
      }
    }
  }

  .info-img {
    img {
      width: 64px;
      height: 64px;
      margin-right: 5px;
      margin-bottom: 5px;
      border: $-solid-border;
    }
  }

  .info-logistics {
    background: #f5f5f5;
    padding: 45px 40px;

    .logistics-list {
      &::after {
        content: "";
        width: 1px;
        top: 10px;
        bottom: 10px;
        background-color: #c8c8c8;
        position: absolute;
        left: 5px;
      }

      .logistics-item {
        align-items: flex-start;
        padding-left: 25px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;

          .item-detail {
            .detail-item {
              &:last-child {
                &::before {
                  background-color: #cf0101;
                }
              }
            }
          }
        }

        .item-time {
          margin-right: 25px;
          flex-shrink: 0;

          span {
            margin-right: 15px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .item-detail {
          color: #444;

          .detail-item {
            margin-bottom: 15px;
            line-height: 1.5;

            &::before {
              content: "";
              display: block;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background-color: #c4c4c4;
              position: absolute;
              left: 2px;
              transform: translateY(7px);
              z-index: 2;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.item-img {
  width: 64px;
  height: 64px;
}</style>